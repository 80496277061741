import ApplicationLayout from '@/Layouts/Application'
import React from 'react'

export const PureIndexPage = () => <h1>Home page</h1>

export const IndexPage = () => (
  <ApplicationLayout preferBits={true}>
    <PureIndexPage />
  </ApplicationLayout>
)

export default IndexPage
